<template>
  <div class="more bg-blue-grey-10 q-pt-sm">
    <!-- scrol -->
    <!-- 筛选制作国 -->
    <div class="rtl q-px-sm scrol_items Ukij" ref="scrollBox">
      <div
        :class="['q-mr-sm e-p-x5-y10 border-r-8', scrolActive1 == item.id ? 'bg-main-color text-white' : 'bg-grey-4 text-grey-7']"
        v-for="item in lists1" :key="item.id" @click="onClickScrolList1(item)">
        <div class="text-ellipsis">{{ item.name}}</div>
      </div>
    </div>

    <!-- 筛选电影类型 -->
    <div class="rtl q-px-sm q-mt-sm scrol_items Ukij" ref="scrollBox">
      <div
        :class="['q-mr-sm e-p-x5-y10 border-r-8', scrolActive2 == item.id ? 'bg-main-color text-white' : 'bg-grey-4 text-grey-7']"
        v-for="item in lists2" :key="item.id" @click="onClickScrolList2(item)">{{ item.name }}</div>
    </div>

    <!-- 筛选电影播放年份 -->
    <div class="rtl q-px-sm q-mt-sm scrol_items Ukij" ref="scrollBox">
      <div
        :class="['q-mr-sm e-p-x5-y10 border-r-8', scrolActive3 == item.id ? 'bg-main-color text-white' : 'bg-grey-4 text-grey-7']"
        v-for="item in yearList" :key="item.id" @click="onClickScrolList3(item)">{{ item.name }}</div>
    </div>

    <!-- 筛选电影语言 -->
    <div class="rtl q-px-sm q-mt-sm scrol_items Ukij" ref="scrollBox">
      <div
        :class="['q-mr-sm e-p-x5-y10 border-r-8', scrolActive5 == item.id ? 'bg-main-color text-white' : 'bg-grey-4 text-grey-7']"
        v-for="item in language_type_arr" :key="item.id" @click="onClickScrolList5(item)">{{ item.name }}</div>
    </div>

    <!-- 筛选付费或免费 -->
    <div class="rtl q-px-sm q-mt-sm scrol_items Ukij" ref="scrollBox">
      <div
        :class="['q-mr-sm e-p-x5-y10 border-r-8', scrolActive4 == item.id ? 'bg-main-color text-white' : 'bg-grey-4 text-grey-7']"
        v-for="item in isFreeFilterList" :key="item.id" @click="onClickScrolList4(item)">{{ item.name }}</div>
    </div>

    <!-- loading -->
    <div class="fixed z-index1 width_100vw Dflex justify-center" v-if="loading" style="top: 50%">
      <q-spinner-cube color="teal-5" size="5.5em" />
    </div>

    <!-- movie -->
    <div class="q-px-sm rtl q-pt-md row q-mb-xl" v-scroll="onScroll" ref="scrollFun">
      <!-- 骨架 -->
      <div v-if="movieDate" class="rtl q-px-sm q-my-sm row full-width justify-between">
        <div v-for="item in 12" class="example-item width_30p q-mb-md box-list">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieDate" v-for="(item, index) in movies" :key="index" transition="scale"
        class="example-item width_30p q-mb-md movie_box" @click="onClickMovie(item, 5)">
        <div class="height_150 q-mb-sm border-r-8 overflow-h relative-position">
          <q-img :src="item.image_url" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity e-t-0 e-l-0 text-orange-10 fontW-900 font_size12">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
            </div>
          </div>
          <div class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{item.name}}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year}}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row justify-between" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>

    <!-- 保险空 -->
    <div class="text-center text-grey-3 Ukij" style="margin-bottom: 100px;">-- تۈگىدى --</div>

    <!-- 防止白底 -->
    <div class="bg-blue-grey-10 z-index-1 fixed height_500 e-b-0 full-width"></div>

    <!-- tab -->
    <Tab></Tab>
  </div>
</template>

<script>
import Tab from '../../components/tab/Tab.vue';
import {getCategory, getHouse} from '../../request/api';
import { debounce } from 'quasar';
import { reqUrl } from '../../request/url.js';
export default {
  name: 'TVseries',
  components: {
    Tab
  },
  data() {
    return {
      lists1: [],
      lists2: [],
      yearList: [
        { id: -1, name: 'بارلىق' },
        { id: 1, name: '2024' },
        { id: 2, name: '2023' },
        { id: 3, name: '2022' },
        { id: 4, name: '2021' },
        { id: 5, name: '2020' },
        { id: 6, name: '2019' },
        { id: 7, name: '2018' },
        { id: 8, name: '2017' },
        { id: 9, name: '2016' },
        { id: 10, name: '2015' },
      ],
      isFreeFilterList: [
        { id: -1, name: 'بارلىق' },
        { id: 1, name: 'ھەقلىق' },
        { id: 2, name: 'ھەقسىز' },
      ],
      language_type_arr: [
        { id: -1, name: 'بارلىق' },
        { id: 1, name: 'ئۇيغۇرچە' },
        { id: 2, name: 'خەنزۇچە' },
        { id: 3, name: 'ئەسلى تىل' }
      ],
      reqUrlData: reqUrl,
      movies: [],
      scrolActive1: -1,
      scrolActive2: -1,
      scrolActive3: -1,
      scrolActive4: -1,
      scrolActive5: -1,
      pageNo: 1,
      pageSize: 10,
      totalPage: null,
      loading: false,
      movieDate: true, // 骨架
      status: 1,
      countryId: -1,
      is_multiset: 1,
      recommend: -1,
      popular: -1,
      is_free: -1,
      play_year: -1,
      language_type: -1,
      categoryId: -1,
    }
  },
  watch: {
    pageNo (val) {
      this.pageNo = val
      this.loading = true
      this.getPost()
    }
  },
  methods: {
    //获取内容
    getPost() {
      if (this.$route.query.category && this.$route.query.category > 0) {
        this.categoryId = this.$route.query.category;
        this.scrolActive2 = this.$route.query.category;
        this.$router.replace({
          path: this.$route.path,
          query: {}
        });
      }
      
      if (this.$route.query.country && this.$route.query.country > 0) {
        this.countryId = this.$route.query.country;
        this.scrolActive1 = this.$route.query.country;
        this.$router.replace({
          path: this.$route.path,
          query: {}
        });
      }

      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let is_multiset = this.is_multiset;
      let status = this.status;
      let countryId = this.countryId;
      let recommend = this.recommend;
      let popular = this.popular;
      let is_free = this.is_free;
      let play_year = this.play_year;
      let language_type = this.language_type;
      let categoryId = this.categoryId;

      getHouse(pageNo, pageSize, status, countryId, is_multiset, recommend, popular, is_free, play_year, language_type, categoryId).then(res => {
        if(res.status == 200) {
          this.totalPage = res.data.totalPage
          this.movies = this.movies.concat(res.data.data)
          this.loading = false
          this.movieDate = false
        }

      }).catch()
    },

    // 筛选电影制作国家
    onClickScrolList1(item) {
      this.scrolActive1 = item.id
      this.pageNo = 1
      this.countryId = item.id

      this.getPost();
    },

    // 筛选电影类型
    onClickScrolList2(item) {
      this.scrolActive2 = item.id
      this.pageNo = 1
      this.categoryId = item.id
      this.movies = []
      
      this.getPost();
    },

    // 筛选电影播放年份
    onClickScrolList3 (item) {
      this.scrolActive3 = item.id
      this.movies=[];
      this.pageNo = 1;
      this.play_year = parseInt(item.name, 10)

      this.getPost();
    },

    // 筛选电影播付费或免费
    onClickScrolList4 (item) {
      this.scrolActive4 = item.id
      this.pageNo = 1
      this.movies = [];
      
      if (item.id == 1) {
        this.is_free = 1;
      } else if (item.id == -1) {
        this.is_free = -1;
      } else {
        this.is_free = 0;
      }

      this.getPost();
    },

    // 筛选电影语言
    onClickScrolList5 (item) {
      this.scrolActive5 = item.id;
      this.pageNo = 1;
      this.language_type = item.id;
      this.movies = [];

      this.getPost();
    },

    // 获取类目
    reqCategorys() {
      getCategory().then(res => {
        if (res.status == 200) {
          this.lists1 = res.data.country
          let all_1 = { id: -1, name: 'بارلىق' }
          this.lists1.unshift(all_1)

          this.lists2 = res.data.data
          let all_2 = { id: -1, name: 'بارلىق' }
          this.lists2.unshift(all_2)
        }
      }).catch(err => {
        console.log('err: ', err);
      })
    },

    // 点击电影
    onClickMovie (item, type) {
      let data = {
        type: type,
        id: item.id
      }
      this.$router.push({ path: '/detailed', query: data })
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },

    onScroll: debounce(function (position) {
      let scrollTop = this.$refs.scrollFun.scrollTop;
      let clientHeight = this.$refs.scrollFun.clientHeight;
      let scrollHeight = this.$refs.scrollFun.scrollHeight;
      let height = 50;
      if(scrollTop +clientHeight >= scrollHeight - height){
        if(this.pageNo === this.totalPage){
          return false
        } else if (this.pageNo < this.totalPage) {
          this.pageNo = this.pageNo + 1
        }
      }else{
        return false
      }

      // if (winHeight + st >= docHeight && position !==0) {
      //   this.pageNo = this.pageNo + 1
      // }
    }, 200)
  },
  created() {
    this.scroll_top()
    this.reqCategorys()
    this.getPost()
  }
}
</script>

<style scoped>
@import "../../assets/style/style.css";
.q-btn-group {
  box-shadow: none;
}
.scrol_items {
  display: flex;
  overflow-x: scroll;
}
.movie_box {
  margin-left: 5%;
}
.movie_box:nth-child(3n){
  margin-left: 0;
}
.example-item {height: 210px;}
</style>
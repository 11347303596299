<style>
.q-carousel__thumbnail {height: 30px !important;}
.fa-circle { font-size: 7px !important; }
.q-carousel__navigation--bottom { bottom: 0px !important; }
.q-carousel__navigation .q-btn { margin: 0px !important; }
.q-tab__label { font-size: 18px; }
</style>
<template>
  <div class="home">

    <!-- 选项 -->
    <div class="q-gutter-y-md" style="max-width: 600px">
      <q-tabs v-model="tab" dense align="left" class="bg-primary text-white shadow-2" :breakpoint="0">
        <q-tab name="mails" label="全部" />
        <q-tab name="mails_1" label="足球" />
        <q-tab name="alarms" label="UFC" />
      </q-tabs>
    </div>

    <div class="q-px-sm">
      <!-- 比赛 -->
      <div class="q-mt-md" v-for="item in homeData">
        <div class="row justify-between">
          <div><q-badge color="purple" text-color="white" :label="item.start_date" /></div>
          <q-img v-if="item.is_free == 1" src="../../public/vip.svg" style="width: 40px; height: 20px;" />
          <q-img v-else src="../../public/mianfei.svg" style="width: 40px; height: 20px;" />
        </div>

        <div class="row justify-between align-center shadow-2 q-py-md q-px-sm" @click="onClick(item)">
          <div class="width_20p">
            <div>{{ item.start_time }}</div>
            <div>{{ item.competition_type }}</div>
          </div>

          <div class="row align-center">
            <div class="column align-center">
              <div>
                <img :src="item.team_a_logo" style="width: 30px; height: 30px" />
              </div>
              <div>{{ item.team_a }}</div>
            </div>
            <div class="q-mx-sm">VS</div>
            <div class="column align-center">
              <div>
                <img :src="item.team_b_logo" style="width: 30px; height: 30px" />
              </div>
              <div>{{ item.team_b }}</div>
            </div>
          </div>

          <div class="width_20p">
            <q-badge v-if="item.start_status == '未开始'" color="red q-pa-sm">{{ item.start_status }}</q-badge>
            <q-badge v-else color="blue q-pa-sm">{{ item.start_status }}</q-badge>
          </div>
        </div>
      </div>
    </div>

    <!-- 返回顶部 -->
    <div v-if="showBackToTop" class="back-to-top">
      <div @click="scroll_top">
        <img :src="`${reqUrlData.url.icon_url}toTop.svg`" style="width: 50px; height: 50px" />
      </div>
    </div>

    <!-- 防止白底 -->
    <div class="z-index-1 height_100 e-b-0 full-width"></div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { getuserInfo, reqIndex, reqLogin, payment, getTargetPrices, updateUserAuth  } from '../request/api.js';
import { debounce } from 'quasar';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Autoplay, EffectCoverflow } from 'swiper/modules';
import { useHomeStore, useHomeCategorySwiperStore, useHomeActorStore } from '../store/homeStore.js';
import { reqUrl } from '../request/url.js';

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      homeData: [],
      pageSize: 10,
      totalPage: null,
      tab: 'mails',
      showBackToTop: false,
    }
  },
  watch: {
    pageNo (val) {
      this.pageNo = val;
      this.loading = true;
      this.getAllMovie();
    }
  },
  methods: {
    //首页请求
    async getIndex() {
      let pageNo = 1;
      let pageSize = 10;
      await reqIndex(pageNo, pageSize).then(res => {
        if(res.status == 200) {
          this.homeData = this.homeData.concat(res.data.data);

          
          for (let item of this.homeData) {
            var date = new Date(item.start_date * 1000)

            // 或者，使用特定的格式
            var formattedDate = date.getFullYear() + '-' +
              ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
              ('0' + date.getDate()).slice(-2);

            item.start_date = formattedDate;
          }
        }
      }).catch(err => {
        console.log('err: ', err);
      })

    },

    // 点击详细
    onClick(item) {
      // let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      
      let dataP = { id: item.id }
      this.$router.push({ path: '/detailed', query: dataP })
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // 平滑滚动至顶部
    },

    // 登录请求
    reqLoginFun() {
      reqLogin().then(res => {
        if (res.status == 200) {
          window.location.href = res.data
        }
      }).catch()
    },

    // 把 code 和 用户信息 存到缓存
    isCode() {
      if (window.location.search.includes('code=')) {
        localStorage.setItem('isCode', this.$route.query.code)
        let code = this.$route.query.code
        let data = { code }
        getuserInfo(data).then(res => {
          const userInfo = res.data.data
          const token = res.data.token
          localStorage.setItem("userInfo", JSON.stringify(userInfo))
          localStorage.setItem("token", token)
        }).catch()
      }
      return localStorage.getItem("isCode") !== null
    },


    onScroll: debounce(function (position) {
      let scrollTop = this.$refs.scrollFun.scrollTop;
      let clientHeight = this.$refs.scrollFun.clientHeight;
      let scrollHeight = this.$refs.scrollFun.scrollHeight;
      let height = 400;
      if (scrollTop + clientHeight >= scrollHeight - height) {
        if (this.pageNo === this.totalPage) {
          return false
        } else if (this.pageNo < this.totalPage) {
          this.pageNo = this.pageNo + 1
        }
      } else {
        return false
      }
    }, 200),

    // 监听滚动事件
    handleScroll () {
      if (window.scrollY > 700) { // 当滚动超过700px时显示按钮
        this.showBackToTop = true;
      } else {
        this.showBackToTop = false;
      }
    },
    
    // 支付
    onClickPayMent () {
      let vipDays = this.vip_days
      let vipType = this.vipType
      let data = { bodyCon: this.bodyCon, commodity_type: this.vipType, price: 100 }
      let _this = this;
      payment(data).then(res => {
        function onBridgeReady () {
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
            "appId": res.data.appId,     //公众号ID，由商户传入     
            "timeStamp": res.data.timeStamp,         //时间戳，自1970年以来的秒数     
            "nonceStr": res.data.nonceStr, //随机串     
            "package": res.data.package,
            "signType": res.data.signType,         //微信签名方式：     
            "paySign": res.data.paySign //微信签名 
          },
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                let userInfo = JSON.parse(localStorage.getItem("userInfo"))
                let user_id = userInfo.id
                // let user_id = 1
                let vip_days = vipDays
                let type = vipType
                let data = { user_id, vip_days, type }
                updateUserAuth(data).then(res => {
                  // alert(`支付成功，发起更新请求也成功了: ${res}`)
                  alert('支付成功')

                }).catch(err => { })
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              }
            });
        }
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
          }
        } else {
          onBridgeReady();
        }
      }).catch(err => {
        console.log("err: ", err)
      })
    },

  },
  created() {
    this.scroll_top();
    if (!this.isCode()) this.reqLoginFun();
    this.getIndex();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped>
@import "../assets/style/style.css";

.background-none { background-color: transparent !important; }

.transparent-bg {
  top: 60px;
  background-color: rgba(0, 0, 0, 0.8);
}

.searchConBox {
  height: 450px;
}
.title_right {
  width: 5px;
  border-radius: 50px;
}
.box-list {
  margin-left: 5%;
}
.box-list:nth-child(3n) {
  margin-left: 0;
}
.example-item {height: 210px;}
.example-item1 {height: 160px;}
.example-item2 {height: 70px;}
.swiper {
  width: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  /* width: 400px; */
  /* height: 300px; */
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.rotate-360 {
  transition: transform 1s;
  transform-origin: center center;
  transform: rotate(360deg);
}

.back-to-top {
  position: fixed;
  bottom: 140px;
  right: 20px;
  /* display: none; */
  /* 默认隐藏 */
  background-color: #f44336;
  width: 50px;
  height: 50px;
  /* padding: 10px 20px; */
  border-radius: 50%;
  z-index: 2;
}
</style>

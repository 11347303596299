<style>
.q-notification {
  direction: rtl;
  font-family: "Ukij";
}
</style>

<template>
  <div class="detailed bg-blue-grey-10 e-pb-100 height_full_vh">
    <!-- 播放视频 -->
    <div v-if="detailedId > 0 ">

      <div style="background-color: #000;" class="relative">
        <div v-if="!videoUrl" style="height: 200px;"></div>
        <video id="my-player" v-else class="video-item" :src="detailed.url" controls autoplay preload="auto" ref="video"
          @canplay="theCurrenVideo" playsinline="true" webkit-playsinline="true" x5-playsinline="true"
          x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-orientation="portraint" tabindex="2"
          mediatype="video" bindplay="" bindpause="" bindended="" bindtimeupdate="" bindwaiting="" binderror="">
        </video>
      </div>

      <div class="q-mt-lg">
        <div class="row text-white justify-between">
          <div class="row align-center flex-end width_45p">
            <span>{{ detailed.team_a }}</span>
            <img class="q-ml-sm" :src="detailed.team_a_logo" style="height: 40px;" />
          </div>
          <div class="row align-center">VS</div>
          <div class="row align-center flex-start width_45p">
            <img class="q-mr-sm" :src="detailed.team_b_logo" style="height: 40px;" />
            <span>{{ detailed.team_b }}</span>
          </div>
        </div>

        <!-- <div class="row justify-between q-mt-sm text-white">
          <div class="width_43p row flex-end">1</div>
          <div class="width_43p row flex-start">3</div>
        </div> -->
      </div>

      <div class="row items-center q-mt-md justify-center">
        <q-btn color="primary" size="lg" @click="refreshPage()">
          <div class="ellipsis">直播无法观看请点击此按钮页面刷新</div>
        </q-btn>
      </div>

      <h5 class="text-white text-center">正在完善，请见谅，谢谢！</h5>

      <div class="bg-blue-grey-10 Dcolumn justify-center align-center">
        <div class="text-white q-mb-sm font_size20">管理员微信</div>
        <q-img src="../../../public/img/ewm.png" style="width: 200px;" />
      </div>

      <!-- 防止白底 -->
      <div class="bg-blue-grey-10" style="height: 100px"></div>

    </div>

    <!-- 会员提示 -->
    <div class="fixed e-t-0 zIndex1 height_full_vh bg-grey-5 width_100vw Dcolumn align-center e-pt-100"
      v-if="isPlay_new">
      <div class="e-border-a-orange-2 q-px-lg q-py-sm text-white bg-orange-9 font_size20" @click="onClickPayMent()">点击付款 1 元继续观看</div>
    </div>
  </div>

</template>

<script>
// import Player from 'xgplayer';
import videojs from 'video.js';
import { getIdHouse, payment, updateUserAuth } from '../../request/api';
import { ref } from 'vue';
import { reqUrl } from '@/request/url.js';
export default {
  name: 'detailed',
  data () {
    return {
      pageType: ref(null),
      categoryId: null,
      detailedId: null,
      detailed: {},
      videoUrl: false,
      videoName: false,
      ratingModel: 3,
      active_multiset_num: 1,
      movies: [],
      commentsArr: [],
      commentNum: '10+',
      maskHeight: 0,
      maskStatus: false,
      videoCon: false,
      videoLoading: false,
      delPagetab: 'moviDel',
      pageNo: 1,
      isPlay: false,
      isPlay_new: false,
      oneStart: 1, // 一个星星
      reqUrlData: reqUrl,
      likeList: [
        { id: 1, name: 'بەھىرلەش', icon: `${reqUrl.url.vipAtushIconUrl}wechat_forward.svg`},
        { id: 2, name: 'ساقلاش', icon: `${reqUrl.url.vipAtushIconUrl}shoucang.svg`},
        { id: 3, name: 'يارىدى', icon: `${reqUrl.url.vipAtushIconUrl}dianzan.svg`},
        { id: 4, name: 'ئەزالىق', icon: `${reqUrl.url.vipAtushIconUrl}vip_top.svg`},
      ],
      shareDialog: false,
      imageBuffer: null,
      videoLength: 0, // 电影长度
      videoDuration: 0, // 电影长度
      movieType: 0, // 电影类型
      commentText: '', // 评论
      actorsDataArr: [],
      player: null,
      recommend: -1,
      popular: -1,

      videoJsAdUrl: '',
      videoJsUrl: '',
      // 视频模块
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            withCredentials: true,
            //type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            type: "application/x-mpegurl",
            src: "",
          },
        ],
        controls: true,
        hls: true,
        poster:
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg", // 你的封面地址
        width: 600, // 播放器宽度
        height: 600,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          progressControl: true,
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          fullscreenToggle: true, // 全屏按钮
        },
        flash: { hls: { withCredentials: true } },
        html5: { hls: { withCredentials: true } },
      },
    }
  },
  methods: {
    // 当前播放视频
    theCurrenVideo() {
      let my_player = document.getElementById('my-player')
      let videoLength = this.$refs.video.duration // 视频总长度
      // let video1 = this.$refs.video.currentTime // 当前播放时间
      
      this.maskHeight = my_player.clientHeight
      // this.videoLength = parseInt(`${this.videoDuration / 60}`, 10)

      // if(videoLength != NaN) this.videoLength = parseInt(videoLength / 60, 10)
      if(videoLength) {
        this.videoLength = parseInt(videoLength / 60, 10)
      } else {
        this.videoLength = 0;
      }

      let _this = this;
      my_player.addEventListener("timeupdate", function() {
        let timeDisplay = Math.floor(my_player.currentTime);
        if(!_this.isPlay && timeDisplay >= 900) {
          my_player.pause()
          // _this.player.pause()
          _this.maskStatus = true;
        }
      })
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo(0, 0);
    },

    // 获取地址兰参数
    getQueryParam() {
      this.pageType = this.$route.query.type
      this.detailedId = this.$route.query.id
      this.categoryId = this.$route.query.category
      this.getPost()
    },

    // 获取详细内容
    async getPost() {
      let data = { postId: this.detailedId }
      getIdHouse(data).then(async res => {
        if (res.status == 200) {
          // this.isPlay_new = await res.data.isPlay;
          this.detailed = await res.data.data;
          this.playerOptions.sources[0].src = await res.data.data.url
          this.posterUrl = await res.data.data.image_url
          this.videoJsUrl = await res.data.data.url
          this.isPlay = await res.data.isPlay
          if (res.data.data.url){     
            // && == 和  ھەمدە
            this.videoUrl = true
          }
          this.startTimer(res.data.isPlay);
          
          const timestamp = this.detailed.playTime;
          const date = new Date(timestamp * 1000);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          this.detailed.playTime = `${day}-${month}-${year}`;
        }
      });
    },

    // 检查是否全屏
    isFullScreen () {
      return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
    },
    exitFullScreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    
    // 一分钟倒计时
    startTimer (isPlay) {
      // this.isPlay_new = await res.data.isPlay;
      setTimeout(() => {
        if (!isPlay) {
          this.isPlay_new = true;
          if (this.isFullScreen()) {
            this.exitFullScreen();
          }
        }
      }, 60000)
    },

    // 支付
    onClickPayMent () {
      let vipDays = this.vip_days
      let vipType = this.vipType
      let data = { bodyCon: this.bodyCon, commodity_type: this.vipType, price: 100 }
      let _this = this;
      payment(data).then(res => {
        function onBridgeReady () {
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
            "appId": res.data.appId,     //公众号ID，由商户传入     
            "timeStamp": res.data.timeStamp,         //时间戳，自1970年以来的秒数     
            "nonceStr": res.data.nonceStr, //随机串     
            "package": res.data.package,
            "signType": res.data.signType,         //微信签名方式：     
            "paySign": res.data.paySign //微信签名 
          },
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                let userInfo = JSON.parse(localStorage.getItem("userInfo"))
                let user_id = userInfo.id
                // let user_id = 1
                let vip_days = vipDays
                let type = vipType
                let data = { user_id, vip_days, type }
                updateUserAuth(data).then(res => {
                  // alert(`支付成功，发起更新请求也成功了: ${res}`)
                  alert('支付成功')
                  _this.isPlay_new = false;
                }).catch(err => { })
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              }
            });
        }
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
          }
        } else {
          onBridgeReady();
        }
      }).catch(err => {
        console.log("err: ", err)
      })
    },
    
    // 刷新页面
    refreshPage() {
      this.$router.go(0);
      // location.reload();
    },

    // 登录请求
    reqLoginFun () {
      reqLogin().then(res => {
        if (res.status == 200) {
          window.location.href = res.data
        }
      }).catch()
    },

    // 把 code 和 用户信息 存到缓存
    isCode () {
      if (window.location.search.includes('code=')) {
        localStorage.setItem('isCode', this.$route.query.code)
        let code = this.$route.query.code
        let data = { code }
        getuserInfo(data).then(res => {
          const userInfo = res.data.data
          const token = res.data.token
          localStorage.setItem("userInfo", JSON.stringify(userInfo))
          localStorage.setItem("token", token)
        }).catch()
      }
      return localStorage.getItem("isCode") !== null
    },
    
  },
  created() {
    this.scroll_top()
    if (!this.isCode()) this.reqLoginFun();
    this.getQueryParam()
  },
  mounted () {
  },
  beforeDestroy () {
  }
}
</script>

<style scoped>
.multiSetsWidth { width: 13.9%; }
.multiSetsLeft { margin-left: 3.3%; }
.multiSetsLeft:nth-child(6n) { margin-left: 0; }
.video-item{
  width: 100%;
}
.movie_box {
  margin-left: 5%;
}
.movie_box:nth-child(3n){
  margin-left: 0;
}
.noVipMask {
  background-color: rgba(47, 47, 47, 0.9);
}
.b3 { bottom: 3px; }
.r3 { right: 3px; }
.loader {
  /* width: 45px; */
  height: 10px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#01D5CB 0 0);
  background: 
    var(--c) 0%   100%,
    var(--c) 50%  100%,
    var(--c) 100% 100%;
  animation: l2 1s infinite linear;
}
@keyframes l2 {
  0%  {background-size: 20% 100%,20% 100%,20% 100%}
  20% {background-size: 20% 60% ,20% 100%,20% 100%}
  40% {background-size: 20% 80% ,20% 60% ,20% 100%}
  60% {background-size: 20% 100%,20% 80% ,20% 60% }
  80% {background-size: 20% 100%,20% 100%,20% 80% }
  100%{background-size: 20% 100%,20% 100%,20% 100%}
}
.videoLoading {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
